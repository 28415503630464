import { openDB } from 'idb'

const dbName = 'indicadores-v2'
const dbVersion = 5

const dbPromise = openDB(dbName, dbVersion, {
    upgrade(db) {
        if (!db.objectStoreNames.contains('ceps')) {
            const ceps = db.createObjectStore('ceps', { keyPath: '_id', autoIncrement: true })
            ceps.createIndex('logradouro', 'logradouro', { unique: false })
            ceps.createIndex('cep', 'cep', { unique: false })
        }

        if (!db.objectStoreNames.contains('cnjs')) {
            const cnjs = db.createObjectStore('cnjs', { keyPath: 'cns', autoIncrement: true })
            cnjs.createIndex('cns', 'cns', { unique: false })
            cnjs.createIndex('nome', 'nome', { unique: false })
        }

        if (!db.objectStoreNames.contains('dados')) {
            db.createObjectStore('dados', { keyPath: 'id' });
        }
    },
})

export default dbPromise