import moment from 'moment';
import { Buffer } from 'buffer'

sessionStorage.setItem('alerta', 'true')

export async function autenticar(that) {
    if(that.autenticacao.autenticado == false){
        return false
    }

    /**
     * Limpa os dados do URL
     */
    window.location.hash = ''

    /**
     * Verifica se existe o token de autenticação
     */
    if(!sessionStorage.getItem('access_token')){
        toastAlert('Você não está autenticado, redirecionando para a autenticação...')

        setTimeout(() => {
            /**
             * Desloga da aplicação
             */
            sair(that)
        }, 2000)

        /**
         * Define os dados nas variaveis
         */
        that.autenticacao.autenticado = false

        return false
    }

    /**
     * Define os dados nas variaveis
     */
    that.autenticacao.autenticado = true
    that.autenticacao.refresh_token = sessionStorage.getItem('refresh_token')
    that.autenticacao.access_token = sessionStorage.getItem('access_token')
    that.autenticacao.token_type = sessionStorage.getItem('token_type')

    /**
     * Transforma o token JWT em um array, quebrando pelo caractere ponto (.)
     *
     * @var {string} sHeader
     * @var {string} sPayload
     * @var {string} sSignature
     */
    const [sHeader, sPayload, sSignature] = that.autenticacao.access_token
        .toString()
        .split('.')

    /**
     * Transforma o dado em base64 para string legível
     *
     * @var {Object} oDadosDescriptografados
     */
    const oDadosDescriptografados = JSON.parse(
        (Buffer.from(sPayload, 'base64'))
        .toString('ascii')
    )
    
    /**
     * Verifica se o usuario é admin
     */
    if(oDadosDescriptografados['grupos']?.includes('Administradores')
        || oDadosDescriptografados['grupos']?.includes('administradores')
        || oDadosDescriptografados['grupo']?.includes('Administradores')
        || oDadosDescriptografados['grupo']?.includes('administradores')
    ){
        /**
         * Define os dados nas variaveis
         */
        that.autenticacao.admin = true
    }

    /**
     * Verifica se o usuario é escreventes
     */
    if(oDadosDescriptografados['grupos']?.includes('Escreventes')
        || oDadosDescriptografados['grupos']?.includes('escreventes')
        || oDadosDescriptografados['grupo']?.includes('Escreventes')
        || oDadosDescriptografados['grupo']?.includes('escreventes')
    ){
        /**
         * Define os dados nas variaveis
         */
        that.autenticacao.escreventes = true
    }

    /**
     * Verifica se o usuario é coordenadores
     */
    if(oDadosDescriptografados['grupos']?.includes('Coordenadores')
        || oDadosDescriptografados['grupos']?.includes('coordenadores')
        || oDadosDescriptografados['grupo']?.includes('Coordenadores')
        || oDadosDescriptografados['grupo']?.includes('coordenadores')
    ){
        /**
         * Define os dados nas variaveis
         */
        that.autenticacao.coordenadores = true
    }

    /**
     * Verifica se o usuario é digitadores
     */
    if(oDadosDescriptografados['grupos']?.includes('Digitadores')
        || oDadosDescriptografados['grupos']?.includes('digitadores')
        || oDadosDescriptografados['grupo']?.includes('Digitadores')
        || oDadosDescriptografados['grupo']?.includes('digitadores')
    ){
        /**
         * Define os dados nas variaveis
         */
        that.autenticacao.digitadores = true
    }

    that.autenticacao.usuario = oDadosDescriptografados.usuario
    that.autenticacao.exp = oDadosDescriptografados.exp

    /**
     * Verifica se o token não está expirado
     */
    if(moment().isAfter(moment.unix(oDadosDescriptografados.exp))){
        await refreshTokenService(that)
    }

    return true
}

export function sair(that) {
    /**
     * Remove os dados do usuario
     */
    sessionStorage.removeItem('access_token')
    sessionStorage.removeItem('refresh_token')
    sessionStorage.removeItem('token_type')

    /**
     * Redireciona para a tela de login
     */
    window.location.href = '/login'
}

export async function refreshTokenService(that){
    /**
    * Define os headers da requisição
    * 
    * @var {object} oHeaders
    */
    const oHeaders = new Headers()                
    oHeaders.append("Content-Type", "application/json")

    /**
    * Define os opções da requisição
    * 
    * @var {object} oOpcoes
    */
    const oOpcoes = {  
        method: 'POST',
        headers: oHeaders,
        mode: 'cors',
        redirect: 'follow',
        body: JSON.stringify({ token: that.autenticacao.refresh_token })
    }

    /**
     * Define se irá exibir alerta
     * 
     * @var {boolean} bAlerta
     */
    const bAlerta = true

    /**
    * Realiza a requisição para buscar os detalhes do usuario
    * 
    * @var {object} oLogin
    */
    const oLogin = await fetch(`${process.env.VUE_APP_ENDPOINT_AUTH}/v1/refreshToken`, oOpcoes)
        .then(async (response) => {
            if(response.status == 401){
                return false
            }

            if (!response.ok) {
                return response.json().then(error => {
                    if(typeof error.codigo == 'string'){
                        error.codigo = `${error.codigo} -`
                    }else{
                        error.codigo = ''
                    }

                    toastAlert(`${error.codigo} ${error.descricao}`)

                    return false
                });
            }
            
            return response.json()
        })
        .then(function(response) {
            if (!response) {
                return false
            }

            return response
        })

    if(!oLogin){
        if(sessionStorage.getItem('alerta') != 'false'){
            toastAlert('Sua sessão expirou, redirecionando para a autenticação...')

            sessionStorage.setItem('alerta', 'false')
        }

        setTimeout(() => {
            /**
            * Desloga da aplicação
            */
            sair(that)
        }, 2000)

        /**
        * Define os dados nas variaveis
        */
        that.autenticacao.autenticado = false

        return false
    }    

    if(oLogin?.access_token){
        sessionStorage.setItem('access_token', oLogin.access_token)
    }

    /**
    * Realizar a autenticação
    */
    await autenticar(that)

    /**
    * Realiza a contagem da expiração
    */
    calcularExpiracao(that)
    
    that.expiracaoInterval = setInterval(() => {
        that.contagemRegressiva = calcularExpiracao(that, true)
    }, 1000)
}

export function calcularExpiracao(that, bRetorno = false){
    const dataAtualUnix = Math.floor(Date.now() / 1000)
    const diferencaSegundos = that.autenticacao.exp - dataAtualUnix

    if(diferencaSegundos <= 10){
        that.contagemRegressivaClass = 'danger'
    }else{
        that.contagemRegressivaClass = 'default'
    }

    if (diferencaSegundos <= 0) {
        clearInterval(that.expiracaoInterval)
        that.contagemRegressiva = "Sessão expirada"
    } else {
        const horas = Math.floor((diferencaSegundos % 86400) / 3600)
        const minutos = Math.floor((diferencaSegundos % 3600) / 60)
        const segundos = diferencaSegundos % 60

        that.contagemRegressiva = `A sessão expira em ${horas < 10 ? '0'+horas : horas}:${minutos < 10 ? '0'+minutos : minutos}:${segundos < 10 ? '0'+segundos : segundos}`
    }

    if(bRetorno){
        return that.contagemRegressiva
    }
}