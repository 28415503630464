<template>
    <div>
        <MarcaDev />
        <Loader v-if="carregando" />
        <div v-show="autenticado && !carregando">
            <section class="section">
                <div class="container">
                    <div class="barra-superior">
                        <div style="display: flex; align-items: center; justify-content: space-between; gap: 2rem;">
                            <img class="amplify-image" alt="Logo" src="/logo.png" style="width: 150px; margin-left: 1.5rem" />
                            <span style="font-size: 24px; margin: auto">Indicadores</span>
                        </div>
                        <div style="margin-right: 2rem; display: flex; gap: 1rem">
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Digitação`" 
                                :onclick="indicador"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Pesquisa`" 
                                :onclick="pesquisa"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Exportação`" 
                                :onclick="exportacao"
                                v-if="autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Documentos`"
                                v-if="autenticacao.admin"
                                :onclick="documentos"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined is-active`" 
                                :titulo="`Endereços`" 
                                v-if="autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Estatísticas`" 
                                :onclick="estatisticas"
                                v-if="autenticacao.coordenadores || autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <div class="dropdown is-right is-hoverable">
                                <div class="dropdown-trigger">
                                    <button class="button is-secondary" aria-haspopup="true" aria-controls="dropdown-menu3">
                                        <i class="gg-more-vertical-alt"></i>
                                    </button>
                                </div>
                                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                                    <div class="dropdown-content">
                                        <a class="dropdown-item" @click="usuariosPagina()" v-if="autenticacao.admin">
                                            Usuários
                                        </a>
                                        <a class="dropdown-item" @click="sair()">
                                            Sair
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="formulario">                              
                        <div class="columns">
                            <div class="column is-full">
                                <b style="font-size: 22px">Filtros dos endereços</b>
                            </div>
                            <div class="column is-4 column-date">
                                <InputText
                                    :label="`Data inicial`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="form.dataInicial"
                                />
                                <span>a</span>
                                <InputText
                                    :label="`Data final`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="form.dataFinal"
                                />
                            </div>

                            <div class="column is-2">
                                <InputText
                                    :label="`CEP`" 
                                    :preenchido="true"
                                    :mascara="'#####-###'"
                                    v-model="form.cep"
                                />
                            </div>   

                            <div class="column">
                                <Select 
                                    :label="`Usuário`" 
                                    :preenchido="true"
                                    :multiple="true"
                                    v-model="form.usuarios"
                                    ref="select"
                                >
                                    <option 
                                        v-for="(usuario, idx) in usuarios" 
                                        :value="usuario"
                                    >{{ usuario }}</option>
                                </Select>
                            </div>                                   
                            
                            <div class="column is-12" style="gap: 1rem; display: flex; justify-content: flex-end;">
                                <Button
                                    :classes="`is-danger is-outlined btn-limpar`" 
                                    :icone="`trash`" 
                                    :titulo="`Limpar filtro`" 
                                    :onclick="limparFiltro" 
                                    :parametro1="1"
                                    v-show="pesquisado"
                                    style="width: 15rem"
                                />

                                <Button
                                    :classes="`is-link is-outlined btn-pesquisar`" 
                                    :icone="`search`" 
                                    :titulo="`Pesquisar`" 
                                    :onclick="pesquisar" 
                                    :parametro1="true" 
                                    :parametro2="true" 
                                    style="width: 15rem"
                                />
                            </div>
                        </div>

                        <hr style="margin-top: -0.5rem;">

                        <Loader 
                            v-if="carregandoBusca" 
                            :altura="20"
                            style="margin-bottom: 2rem;"
                        />
                
                        <div class="columns column-table column-nao-encontrado" v-show="totalRegistros == 0">
                            <div>Nenhum registro encontrado</div>
                        </div>
                        
                        <Pagination 
                            :contadorPaginas.sync="contadorPaginas" 
                            :totalRegistros.sync="totalRegistros" 
                            :paginaAtual.sync="paginaAtual" 
                            :paginas.sync="paginas" 
                            :totalPaginas.sync="totalPaginas"
                                :numeroExibicaoRegistros.sync="numeroExibicaoRegistros"
                            v-show="dados != false"
                        />
                        
                        <div class="columns column-table" v-show="dados != false">
                            <div style="position: absolute; top: 200px; right: 20px;">
                                <Button
                                    :classes="`is-purple btn-pesquisar btn-csv`" 
                                    :icone="`search`" 
                                    :titulo="`Exportar CSV`" 
                                    :onclick="exportar" 
                                    :parametro1="`csv`" 
                                    style="margin-left: -30px; width: 15rem; margin-top: 5px;"
                                />
                            </div>
                            
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th style="min-width: 105px;">CEP</th>
                                        <th style="min-width: 135px;">Usuário</th>
                                        <th style="min-width: 100px;">Data da consulta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(dado, idx) in dados">
                                        <td>{{ dado.cep }}</td>
                                        <td>{{ dado.usuario }}</td>
                                        <td>{{ dado.dataConsulta }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <hr style="margin-top: -0.5rem; margin-bottom: 0.5rem;">

                        <Pagination 
                            :contadorPaginas.sync="contadorPaginas" 
                            :totalRegistros.sync="totalRegistros" 
                            :paginaAtual.sync="paginaAtual" 
                            :paginas.sync="paginas" 
                            :totalPaginas.sync="totalPaginas"
                                :numeroExibicaoRegistros.sync="numeroExibicaoRegistros"
                            v-show="dados != false"
                        />
                    </div>
    
                    <div class="formulario" style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 3rem;
                    ">
                        <Versao :horaAtual="horaAtual" />
                        
                        <Button
                            :classes="`is-${contagemRegressivaClass} btn-small`" 
                            style="margin-right: -45px; width: 16rem;"
                            :onclick="refreshToken"
                            :titulo="`${contagemRegressiva}`" 
                        />
                    </div>
                </div>
            </section>
        </div>
    
        <div class="modal" id="modal-confimacao" :class="{ 'is-active': modalLimpeza }">
            <div class="modal-background" @click="modalLimpeza = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Limpar o filtro</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalLimpeza = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente limpar o filtro?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-link is-outlined" tabindex="-1" @click="modalLimpeza = false">Não</button>
                    <button class="button is-danger is-outlined" @click="limparFiltro(0)">Sim</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import qs from "qs"
    import moment from "moment"
    import { autenticar } from '../../services/auth.js'

    export default {
        name: "Documentos",
        components: {},
        data(){
            return {
                modalLimpeza: false,
                pesquisado: false,
                carregando: false,
                carregandoBusca: false,
                autenticado: true,
                token: {},
                form: {
                    dataInicial: '',
                    dataFinal: '',
                    cep: '',
                    usuarios: []
                },
                autenticacao: {
                    autenticacao: false
                },
                usuarios: [],
                dados: false,
                paginaAtual: 1, 
                totalRegistros: 1,
                totalPaginas: 1,
                contadorPaginas: 100,
                numeroExibicaoRegistros: 100,
                expiracaoInterval: null,
                horaAtual: moment().format('HH:mm'),
                contagemRegressiva: '00:00:00',
                contagemRegressivaClass: 'default'
            }
        },
        async created(){
            /**
             * Realizar a autenticação
             */
            await autenticar(this)

            this.usuarios = await this.buscarUsuarios()

            /**
             * Realizar a pesquisa automatica
             */
            this.pesquisar(false)
        },
        async mounted() {
            /**
             * Realizar a autenticação
             */
            await autenticar(this)

            /**
             * Realiza a contagem da expiração
             */
            this.calcularExpiracao()
            this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)

            /**
             * Eventos para capturar o comando CTRL + Q
             */
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Enter') {
                    const oInputs = document.querySelectorAll('input')

                    oInputs.forEach(input => {
                        input.blur()
                    })

                    this.pesquisar()
                }
            });
        },
        watch: {
            paginaAtual: {
                handler: function (novoValor, valorAntigo) {
                    if(novoValor != valorAntigo){
                        this.pesquisar()
                    }
                }
            }
        },
        computed: {
            paginas() {
                let intervalo = 2

                intervalo = this.paginaAtual == 1 ? 4 : intervalo
                intervalo = this.paginaAtual == 2 ? 3 : intervalo
                intervalo = this.paginaAtual == this.totalPaginas ? 4 : intervalo
                intervalo = this.paginaAtual == this.totalPaginas - 1 ? 3 : intervalo
                
                const primeiraPagina = Math.max(1,  this.paginaAtual - intervalo);
                const ultimaPagina = Math.min(this.totalPaginas,  this.paginaAtual + intervalo);
                
                return Array.from({ length: ultimaPagina - primeiraPagina + 1 }, (_, index) => primeiraPagina + index);
            }
        },
        methods: {
            /**
             * Função para limpar o filtro
             */
            limparFiltro(iTipo){
                if(iTipo == 1){
                    this.modalLimpeza = true
                }else{
                    this.modalLimpeza = false
                    this.pesquisado = false
                    this.totalRegistros = 1
                    this.dados = false
                    this.form = {
                        dataInicial: '',
                        dataFinal: '',
                        cep: '',
                        usuario: ''
                    }

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelect
                     */
                     const oSelect = this.$refs.select;
                    oSelect.clearSelectize()
                    
                    this.pesquisar()
                }
            },
            /**
             * Função para pesquisar
             */
            async pesquisar(bFiltro = true, bResetarPagina = false){
                if(this.form.dataInicial != '' && this.form.dataFinal != '' && moment(this.form.dataInicial).isAfter(moment(this.form.dataFinal))){
                    toastAlert('A data inicial não pode ser maior que a data final')

                    return false
                }

                this.carregado = true
                this.carregandoBusca = true
                this.dados = false

                if(sessionStorage.getItem('parametrosDocumentos') != null && !bFiltro){
                    /**
                     * Pega os parametros do storage
                     * 
                     * @var {object} oParametros
                     */
                    const oParametros = JSON.parse(sessionStorage.getItem('parametrosDocumentos'))
                    
                    this.form.dataInicial = oParametros.dataInicial
                    this.form.dataFinal = oParametros.dataFinal
                    this.form.cep = oParametros.cep
                    this.form.usuarios = oParametros.usuarios
                    this.paginaAtual = oParametros.pagina
                }

                if(bResetarPagina){
                    this.paginaAtual = 1
                }
                
                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                const sParametros = qs.stringify({
                    dataInicial: this.form.dataInicial,
                    dataFinal: this.form.dataFinal,
                    cep: this.form.cep,
                    usuarios: this.form.usuarios,
                    pagina: this.paginaAtual
                })

                sessionStorage.setItem('parametrosDocumentos', JSON.stringify({
                    dataInicial: this.form.dataInicial,
                    dataFinal: this.form.dataFinal,
                    cep: this.form.cep,
                    usuarios: this.form.usuarios,
                    pagina: this.paginaAtual
                }))

                /**
                 * Realizar a autenticação
                 */
                await autenticar(this)
                
                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/consulta/enderecos/historico?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
                .then((response) => {
                    if(this.form.dataInicial == '' && this.form.dataFinal == '' && (!this.form.cep || this.form.cep == '') && (!this.form.usuarios || this.form.usuarios == '')){
                        this.pesquisado = false
                    }else{
                        this.pesquisado = true
                    }

                    this.totalPaginas = Math.ceil(response.data.totalRegistros / this.numeroExibicaoRegistros)
                    this.totalRegistros = response.data.totalRegistros
                    this.contadorPaginas = this.numeroExibicaoRegistros * this.paginaAtual

                    if(this.contadorPaginas > response.data.totalRegistros){
                        this.contadorPaginas = response.data.totalRegistros
                    }

                    this.dados = response.data.data
                })
                .catch((error) => {
                    this.totalRegistros = 0
                    console.log(error)
                    toastAlert(`Erro ao buscar dados${error.response.data.descricao ? `: ${error.response.data.descricao}` : ''}`)
                });

                this.carregandoBusca = false
            },
            /**
             * Função para buscar usuarios
             */
            async buscarUsuarios(){
                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/usuarios`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
                .then((response) => {
                    if(response.data.usuarios){
                        response.data.usuarios = response.data.usuarios.reduce((acc, valor, index) => {
                            acc[valor] = valor;
                            return acc;
                        }, {});

                        this.usuarios = response.data.usuarios
                        
                        /**
                         * Define o select pela referencia
                         *
                         * @var {object} oSelect
                         */
                        const oSelect = this.$refs.select;
                        oSelect.updateOptions(response.data.usuarios)
                        
                        oSelect.clearSelectize()
                    }

                })
                .catch((error) => {
                    console.log(error)
                    toastAlert(`Erro ao buscar os usuários${error?.response?.data?.descricao ? `: ${error.response.data.descricao}` : ''}`)
                });

                this.pesquisar(false)
            },
            /**
             * Função para ir para o cadastro
             */
            indicador(){
                window.location.href = '/indicadores'
            },
            /**
             * Função para ir para o cadastro
             */
            pesquisa(){
                window.location.href = '/pesquisa'
            },
            /**
             * Função para ir para a exportacao
             */
            exportacao(){
                window.location.href = '/exportacao'
            },
            /**
             * Função para ir para os usuarios
             */
            usuariosPagina(){
                window.location.href = '/usuarios'
            },
            /**
             * Função para ir para as estatisticas
             */
            estatisticas(){
                window.location.href = '/estatisticas'
            },
            /**
             * Função para ir para os documentos
             */
            documentos(){
                window.location.href = '/documentos'
            },
            /**
             * Função para sair da aplicação
             */
            sair(){
                sessionStorage.removeItem('access_token')

                window.location.href = '/'
            },
            /**
             * Função para cancular a expiracao
             */
            calcularExpiracao() {
                const dataAtualUnix = Math.floor(Date.now() / 1000)
                const diferencaSegundos = this.autenticacao.exp - dataAtualUnix

                if(diferencaSegundos <= 10){
                    this.contagemRegressivaClass = 'danger'
                }else{
                    this.contagemRegressivaClass = 'default'
                }

                if (diferencaSegundos <= 0) {
                    clearInterval(this.expiracaoInterval)
                    this.contagemRegressiva = "Sessão expirada"
                } else {
                    const horas = Math.floor((diferencaSegundos % 86400) / 3600)
                    const minutos = Math.floor((diferencaSegundos % 3600) / 60)
                    const segundos = diferencaSegundos % 60

                    this.contagemRegressiva = `A sessão expira em ${horas < 10 ? '0'+horas : horas}:${minutos < 10 ? '0'+minutos : minutos}:${segundos < 10 ? '0'+segundos : segundos}`
                }
            },
            /**
             * Função para atualizar o token
             */
            async refreshToken(){
                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")

                /**
                * Define os opções da requisição
                * 
                * @var {object} oOpcoes
                */
                const oOpcoes = {  
                    method: 'POST',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow',
                    body: JSON.stringify({ token: this.autenticacao.refresh_token })
                }

                /**
                * Realiza a requisição para buscar os detalhes do usuario
                * 
                * @var {object} oLogin
                */
                const oLogin = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/refreshToken`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })

                if(!oLogin){
                    toastAlert('Sua sessão expirou, redirecionando para a autenticação...')

                    setTimeout(() => {
                        /**
                        * Desloga da aplicação
                        */
                        this.sair()
                    }, 2000)

                    /**
                    * Define os dados nas variaveis
                    */
                    this.autenticacao.autenticado = false

                    return false
                }    

                if(oLogin?.AccessToken){
                    sessionStorage.setItem('access_token', oLogin.AccessToken)
                }

                /**
                * Realizar a autenticação
                */
                await autenticar(this)

                /**
                * Realiza a contagem da expiração
                */
                this.calcularExpiracao()
                this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)
            },
            /**
             * Função para exportar o relatorio
             */
            async exportar(){
                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                const sParametros = qs.stringify({
                    dataInicial: this.form.dataInicial,
                    dataFinal: this.form.dataFinal,
                    cep: this.form.cep,
                    usuarios: this.form.usuarios,
                    pagina: this.paginaAtual
                })

                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")

                /**
                * Inicializa uma variável para controlar a alternância do texto
                * 
                * @var {int} iTextoCarregando
                */
                let iTextoCarregando = 0

                /**
                * Executa o setInterval para atualizar o texto a cada 250 milissegundos
                * 
                * @var {function} intervalCarregando
                */
                const intervalCarregando = setInterval(() => {
                    /**
                    * Executa o setInterval para atualizar o texto a cada 250 milissegundos
                    * 
                    * @var {object} oBtn
                    */
                    const oBtn = document.querySelector(`.btn-csv > div`)
                    
                    /**
                    * Array com as variações do texto "Carregando"
                    * 
                    * @var {ARRAY} aTexto
                    */
                    const aTexto = ["Carregando&nbsp;&nbsp;&nbsp;", "Carregando.&nbsp;&nbsp;", "Carregando..&nbsp;", "Carregando..."]
                    
                    // Atualize o texto com base no índice atual
                    oBtn.innerHTML = aTexto[iTextoCarregando]
                    
                    // Avance para o próximo índice de texto (circulando no array)
                    iTextoCarregando = (iTextoCarregando + 1) % aTexto.length
                }, 250)

                /**
                 * Realizar a autenticação
                 */
                await autenticar(this)

                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/consulta/enderecos/relatorio?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    response.data = new Blob([response.data])

                    // Cria um link de download do arquivo
                    var url = URL.createObjectURL(response.data)
                    var link = document.createElement("a")
                    link.setAttribute("href", url)
                    link.setAttribute("download", `relatorio_${Date.now()}.csv`)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    clearInterval(intervalCarregando)
                    document.querySelector(`.btn-csv > div`).innerHTML = `Exportar CSV`
                })
                .catch((error) => {
                    console.log(error)
                    toastAlert(`Erro ao exportar os dados`)

                    clearInterval(intervalCarregando)
                    document.querySelector(`.btn-csv > div`).innerHTML = `Exportar CSV`
                })
            }
        }
    }
</script>

<style>
    html{
        overflow: hidden;
        background-color: #E5DDD5;
    }

    .title:not(:last-child), .subtitle:not(:last-child){
        margin-bottom: 0.5em;
    }

    .div-principal{
        height: 100vh;
        overflow: hidden;
    }

    .barra-superior{
        margin: 0;
        height: 50px;
        background: #EDEDED;
        border-right: 1px solid #E1E1E1;
        border-bottom: 1px solid rgb(200, 200, 200);
        display: flex;
        justify-content: space-between;
    }

    .barra-superior .gg-search{
        position: absolute;
        margin: 9px 27px;
        transform: scale(var(--ggs,0.6));
    }

    .barra-superior input{
        width: 100%;
        border: none;
        margin: 0;
        height: 100%;
        outline: none;
        padding-left: 50px;
        color: gray;
    }

    .barra-superior-filtro{
        height: 35px;
    }

    .barra-superior button{
        margin: 0.5rem 2rem;
        font-size: 0.8rem;
    }

    .barra-superior span{
        line-height: 50px;
        margin-left: 25px;
        font-weight: 500;
    }

    .indicador{
        padding: 0;
        background:#E5DDD5;
        position: relative;
    }

    .indicadores{
        height: 83vh;
        margin: 10px 0;
        overflow-y: auto;
        display: grid;
    }

    .columns:last-child{
        margin: 0 -8px;
    }

    .section{
        padding: unset;
    }

    .container{
        max-width: unset;
        overflow-x: hidden;
    }

    .lista-indicadores{
        display: none;
        justify-content: flex-end;
        flex-direction: column;
    }

    .formulario{
        margin: 0 auto;
        position: relative;
        width: 96vw;
        background: #fff;
        padding: 1rem 1rem;
        border-radius: 1rem;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 8rem;
    }

    .indicador{
        height: 98%!important;
        right: -2px;
        top: -11px;
        padding-top: 12px;
    }

    select, .select{
        width: 100%;
    }

    .label{
        margin-top: 0.8rem
    }

    .btn-control{
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        border-top: 1px solid #ddd;
        width: 96vw;
        padding: 1rem 2rem 0rem 2rem;
        margin-left: -1rem;
    }

    .selectize-input{
        padding: 10px 8px!important;
    }

    .formulario .columns{
        flex-wrap: wrap;
        padding: 0.5rem;
        margin-top: -0.5rem!important;
        align-items: flex-end;
        padding-bottom: 15px;
    }

    .formulario .column{
        padding: 0 0.5rem!important;
        margin-top: 0rem!important;
    }

    .formulario .column.is-one-tenth,.column.is-one-tenth-tablet {
        flex: none;
        width: 10%
    }

    .formulario .label {
        margin-top: 0.5rem;
        margin-bottom: 0.2rem!important;
        font-size: 12px;
    }

    .tabs{
        align-items: flex-end;
        margin-bottom: 0px;
        margin-left: -1rem;
        width: 96vw;
    }
    
    .tabs ul{
        padding-left: 2rem;
    }

    .formulario .numero{
        position: absolute;
        right: 25px;
        top: 1rem;
        font-size: 18px;
    }

    .btn-rotate > .icon{
        transform: rotate(270deg);
    }
    
    .column-date{
        display: flex; 
        gap: 1rem;
    }

    .column-date > span{
        margin-top: 2.25rem;
    }

    .column-date > .control{
        width: 100%;
    }

    .selectize-control.multi .selectize-input.has-items{
        padding: 6px 8px!important;
    }

    .column-table{
        border-bottom: 1px solid rgb(200, 200, 200);
        padding-top: 0!important;
        padding-bottom: 0!important;
    }

    .btn-limpar{
        margin-top: 1rem;
    }

    .btn-pesquisar{
        margin-top: 1rem;
        float: right;
    }

    .pagination{
        gap: 1rem;
    }

    .pagination > b{
        margin-left: -1.8rem;
        width: 6rem;
    }

    .pagination, .pagination-list{
        display: inline-flex;
    }

    .pagination-previous{
        order: 0;
    }

    .pagination-previous.is-disabled, .pagination-next.is-disabled{
        opacity: .7;
        pointer-events: none;
    }

    .column-nao-encontrado{
        justify-content: center;
        align-items: center;
    }

    .column-nao-encontrado > div{
        margin: 1rem 1rem 1.5rem 1rem;
    }

    .pagination-link.is-current{
        background-color: #000;
        border-color: #dbdbdb;
    }
</style>