<template>
    <nav class="pagination is-small" role="navigation" aria-label="pagination">
        <div>
            <a class="pagination-previous" :class="{ 'is-disabled': paginaAtualLocal == 1 }" @click="atualizarPagina(paginaAtualLocal - 1)">Anterior</a>
            <ul class="pagination-list">
                <li v-for="pagina in paginasLocal" :key="pagina">
                    <a class="pagination-link" :class="{ 'is-current': pagina === paginaAtualLocal }" @click="atualizarPagina(pagina)" :aria-label="`Ir para a página ${pagina}`" :aria-current="pagina === paginaAtualLocal ? 'page' : null">
                        {{ pagina }}
                    </a>
                </li>
            </ul>
            <a class="pagination-next" :class="{ 'is-disabled': paginaAtualLocal == totalPaginasLocal }" @click="atualizarPagina(paginaAtualLocal + 1)">Próxima</a>
        </div>
        <div>
            <b>Visualizando registros de {{  (numeroExibicaoRegistrosLocal * (paginaAtualLocal - 1)) + 1 }} a {{  numeroExibicaoRegistrosLocal * paginaAtualLocal > totalRegistrosLocal ? totalRegistrosLocal : numeroExibicaoRegistrosLocal * paginaAtualLocal }} de um total de {{ totalRegistrosLocal }}</b>
        </div>
    </nav>
</template>

<script>
    export default {
        name: "Pagination",
        props: ['contadorPaginas', 'totalRegistros', 'paginaAtual', 'paginas', 'totalPaginas', 'numeroExibicaoRegistros'],      
        data() {
            return {
                contadorPaginasLocal: this.contadorPaginas,
                totalRegistrosLocal: this.totalRegistros,
                paginaAtualLocal: this.paginaAtual,
                paginasLocal: this.paginas,
                totalPaginasLocal: this.totalPaginas,
                totalPaginasLocal: this.totalPaginas,
                numeroExibicaoRegistrosLocal: this.numeroExibicaoRegistros
            };
        },
        watch: {
            contadorPaginas(novoValor) {
                this.contadorPaginasLocal = novoValor;
            },
            totalRegistros(novoValor) {
                this.totalRegistrosLocal = novoValor;
            },
            paginaAtual(novoValor) {
                this.paginaAtualLocal = novoValor;
            },
            paginas(novoValor) {
                this.paginasLocal = novoValor;
            },
            totalPaginas(novoValor) {
                this.totalPaginasLocal = novoValor;
            },
            numeroExibicaoRegistros(novoValor) {
                this.numeroExibicaoRegistrosLocal = novoValor;
            }
        },
        methods: {
            atualizarPagina(pagina) {
                this.paginaAtualLocal = pagina;
                this.$emit('update:paginaAtual', pagina);
            }
        }
    }
</script>