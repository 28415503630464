<template>
    <div class="loader-wrapper" :style="estilo">
        <div class="loader is-loading" :style="estiloLoader"></div>
        <div class="progress-container" v-if="progress && progress > 0">
            <div class="progress-bar" :style="{ width: progress + '%' }">
                <span id="progress-text">{{ progress + '%' }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        props: ['altura', 'escala', 'progress'],
        computed: {  
            /**
             * Função para definir a altura da tela no carregamento
             */
            estilo() {
                return {
                    height: `${this.altura ?? 80}vh`,
                    'flex-direction': `${this.progress && this.progress > 0 ? 'column' : 'unset'}`
                }
            },
            /**
             * Função para definir o tamanho do loader
             */
            estiloLoader() {
                const iEscala = this.escala ?? 1

                return {
                    height: `${4 / iEscala}em`,
                    width: `${4 / iEscala}em`,
                    border: `${4 / iEscala}px solid #3273dc`
                }
            }
        },
    }
</script>

<style>
    .loader-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loader-wrapper .loader{
        border-right-color: transparent!important;
        border-top-color: transparent!important;
        -webkit-animation: spinAround .5s infinite linear;
        animation: spinAround .5s infinite linear;
    }
    .progress-container {
        width: 25%;
        background-color: #ddd;
        border-radius: 25px;
        overflow: hidden;
        margin: 20px 0;
    }

    .progress-bar {
        height: 30px;
        width: 0;
        background-color: #4caf50;
        text-align: center;
        line-height: 30px;
        color: white;
        transition: width 0.4s;
    }

    .progress-bar span {
        text-align: center;
        font-weight: bold;
    }
</style>